import React,{useRef, useEffect  } from "react";
import styles from "./BannerHomeEnd.module.scss";
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import produ from "../../../img/home-bannerEnd-produ.png";
import icon from "../../../img/home-bannerEnd-icon.png";

// Registrar el plugin de ScrollTrigger
gsap.registerPlugin(ScrollTrigger);

const BannerHomeEnd = () => {

    const boxImg = useRef(null);
    const boxLogo = useRef(null);
    const boxInfo = useRef(null);

    useEffect(() => {
        gsap.fromTo(boxImg.current, 
          { opacity: 0, y: 20, x:-20 }, 
          { 
            opacity: 1, 
            y: 0,
            x:0,
            scrollTrigger: {
                trigger: boxLogo.current,
                start: 'top 70%',
                end: 'top 0%',
                scrub: true, 
            }
          }
        );
        gsap.fromTo(boxLogo.current, 
            { opacity: 0.5, x: -20}, 
            { 
                opacity: 1, 
                x: 0,
                scrollTrigger: {
                    trigger: boxLogo.current,
                    start: 'top 70%',
                    end: 'top 0%',
                    scrub: true, 
                }
            }
        );
        gsap.fromTo(boxInfo.current, 
            { x: 200}, 
            { 
                x: 0,
                delay:0.5,
                scrollTrigger: {
                    trigger: boxInfo.current,
                    start: 'top 100%',
                    end: 'top 0%',
                    scrub: true, 
                }
            }
        );
    }, []);

    return (
        <section className={styles.baner_home_end}>
            <a 
            className={styles.part_top} 
            href="https://api.whatsapp.com/send?l=es&phone=5491131274647&text=Hola!%20te%20contacto%20desde%20la%20web%20de%obleasdulcesbsas"
            target="_blank"
            >
                <p>Consultar por zona de envío</p>
                <span>
                    <img src={icon}  ref={boxLogo}/>
                </span>
            </a>
            <div className={styles.part_bottom}>
                <div className={styles.box_img}>
                    <img src={produ} ref={boxImg}/>
                </div>
                <div className={styles.box_info} ref={boxInfo}>
                    <h1>¡Disfrutá de las mejores obleas!</h1>
                    <p>Ofertas todos los meses</p>
                </div>
            </div>
        </section>
    );
}
 
export default BannerHomeEnd;