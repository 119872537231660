import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "./Slider.scss";
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import BoxCategory from "../../shared/BoxCategory/BoxCategory";
import BoxProduct from "../../shared/BoxProduct/BoxProduct";
import BoxRelated from "../../shared/BoxRelated/BoxRelated";


const Slider = ({slidesPerView,style,spaceBetween,typeOfItem,items, setItems}) => {

    
    const renderTypeItem = (items) => {
        switch(typeOfItem){
            case "category":
                return items.data.map((i, index) => (
                    <SwiperSlide key={index} className={typeOfItem}>
                        <BoxCategory item={i} />
                    </SwiperSlide>
                ));
            case "product":
                return items.data.map((i, index) => (
                    <SwiperSlide key={index} className={typeOfItem}>
                        <BoxProduct item={i} />
                    </SwiperSlide>
                ));
            case "subcategory":
                return items.data.map((i, index) => (
                    <SwiperSlide key={index} className={typeOfItem}>
                        <BoxRelated item={i}  typeOfItem={typeOfItem} />
                    </SwiperSlide>
                ));
            default:
                return null;
        }
    }

    return ( 
        <div className="container-slider">
                <Swiper
                spaceBetween={spaceBetween}
                slidesPerView={slidesPerView}
                navigation={true}
                modules={[Navigation]}
                breakpoints={{
                    320: {
                      slidesPerView: 1,
                    },
                    520: {
                        slidesPerView: 2,
                      },
                    768: {
                      slidesPerView: 3,
                    },
                    1024: {
                      slidesPerView: slidesPerView,
                    },
                    1440: {
                      slidesPerView: slidesPerView,
                    },
                  }}
                //onSlideChange={() => console.log('slide change')}
                //onSwiper={(swiper) => console.log(swiper)}
                >
                {
                    renderTypeItem(items)
                }
            </Swiper>
        </div>
     );
}
 
export default Slider;