import React from "react";
import styles  from "./BoxRelated.module.scss";
import {useNavigate } from "react-router-dom";

const BoxRelated = ({item, typeOfItem}) => {
    
    const navigate = useNavigate ();

    const handleClick = (route, item, type) => {
        let postData;
        
        if (type === "category") {
            postData = { "id_category": item.id };
        } else if(type === "subcategory") {
            postData = { "id_subcategory": item.id };
        }else{
            postData = { "state": "A" };
        }
        navigate(route, { state: { item, postData } });
    }

    return (
        <ul 
            className={styles.box}
            onClick={() => handleClick("/productos", item, typeOfItem)}
        >
            <li className={styles.img}>
                <img src={item.imgUrl} />
            </li>
            <li className={styles.name}>{item.name}</li>
        </ul>
    );
}
 
export default BoxRelated;


