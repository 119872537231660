import React from "react";
import styles  from "./BoxResult.module.scss";
import Btn from "../../elements/Btn/Btn";
import BtnEditar from "../../../img/BtnEditar";
import BtnBorrar from "../../../img/BtnBorrar";
import axios from "axios";
import {useNavigate } from "react-router-dom";

const BoxResult = ({item,mood, setUpdateP, setModalIsOpen,setItemModal,setActionModal}) => {

    const navigate = useNavigate ();

    const handleDelete = async (itemId) => {
        try {
            const response = await axios.post('https://www.obleasdulcesbsas.com.ar/api/delProduct.php', { id: itemId });
            if (response.status === 200) {
                // Llama a la función pasada como prop para actualizar la lista de productos
                //onProductDeleted(itemId);
                console.log("producto eliminado");
                setUpdateP(prevUpdateP => !prevUpdateP)
            }
        } catch (error) {
            console.error('Error eliminando el producto:', error);
        }
    };

    const handleEdit = (item) => {
        setActionModal("edit")
        setModalIsOpen(true);
        setItemModal(item);
    }
    const formattedPrice = Number(item.price).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

    return (
        <article className={styles.box}>

            <div className={styles.img}>
                <img src={item.imgMain} />
            </div>

            <div className={styles.data}>
                <div className={styles.badge}>
                    {item.badge}
                </div>
                <div className={styles.name}>
                    <span>Nombre: </span>{item.name}
                </div>
                <div className={styles.stock}>
                    Stock:<span>{item.units}</span>
                </div>
                <div className={styles.rubro}>
                    Rubro:<span>{item.rubro}</span>
                </div>
                <div className={styles.sku}>
                    SKU:<span>{item.sku}</span>
                </div>
                <div className={styles.descrip}>
                    {item.descrip}
                </div>
            </div>
            
            <div className={styles.prices}>
            <div className={styles.price}>{"$ " + formattedPrice}</div>
                <div className={styles.containerBtn}>
                    {
                        mood == "admin"?
                        <div className={styles.containerBtn}>
                            <div className="edit" onClick={() => handleEdit(item)}>
                                <BtnEditar />
                            </div>
                            <div className="delete" onClick={() => handleDelete(item.id)}>
                                <BtnBorrar />
                            </div>
                        </div>
                        :
                        <></>
                        /*
                        <Btn 
                            cta
                            type="link"
                            text="Ver Producto"
                            route={item.route}
                        />*/
                    }
                </div>
            </div>
        </article>
    );
}
 
export default BoxResult;


