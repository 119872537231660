import React,{useState} from "react";
import styles from "./Contacto.module.scss";
import Btn from "../../elements/Btn/Btn";
import IconLocation from "../../../img/IconLocation";
import IconSend from "../../../img/IconSend";
import IconPhone from "../../../img/IconPhone";
import BannerHomeEnd from "../../elements/BannerHomeEnd/BannerHomeEnd";
import Alerta from "../../elements/Alertas/Alertas";
import useSendContacto from "../../hooks/useSendContacto";
import axios from "axios";

const Contacto = () => {
    const [correo, establecerCorreo] = useState('');
    const [asunto, establecerAsunto] = useState('');
    const [mensaje, establecerMensaje] = useState('');
    const [estadoAlerta, cambiarEstadoAlerta] = useState(false);
    const [alerta, cambiarAlerta] = useState({});

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const urlContacto = 'https://www.obleasdulcesbsas.com.ar/api/sendMail.php';
    let postData = {};
    //const { data: sendMail, loading: loadingSendMail, error: errorSendMail } = useSendContacto(urlContacto,postData);

    const handleChange = (e) =>{
        if(e.target.name === 'email'){
            establecerCorreo(e.target.value);
        }else if(e.target.name === 'asunto'){
            establecerAsunto(e.target.value);
        }else if(e.target.name === 'mensaje'){
            establecerMensaje(e.target.value);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        cambiarEstadoAlerta(false);
        cambiarAlerta({});

        const expresionRegular = /[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+/;

        try {
            if(!expresionRegular.test(correo)){
                cambiarEstadoAlerta(true);
                cambiarAlerta({
                    tipo: 'error',
                    mensaje: 'Ingresar un correo Electrónico válido.'
                });
                return;
            }
    
            if(correo === '' || asunto === '' || mensaje === ''){
                cambiarEstadoAlerta(true);
                cambiarAlerta({
                    tipo: 'error',
                    mensaje: 'Completar todos los campos.'
                });
                return;
            }else{
                postData = {
                    "asunto":asunto,
                    "destino":"obleasdulces@obleasdulcesbsas.com.ar",
                    "nombre":"Web Obleas",
                    "contacto":correo,
                    "mensaje":mensaje
                }
            }

            const response = await axios.post(urlContacto, postData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            setData(response.data);
            if(data.state == 200){
                cambiarEstadoAlerta(true);
                cambiarAlerta({tipo:'exito',mensaje: "Su mensaje se envió correctamente."});
                establecerCorreo('');
                establecerAsunto('');
                establecerMensaje('');
            }else{
                cambiarEstadoAlerta(true);
                cambiarAlerta({tipo:'error',mensaje: "Hubo un error al intentar enviar el mensaje."});
            }
        } catch (error) {
            setError(error);
            cambiarEstadoAlerta(true);
            cambiarAlerta({tipo:'error',mensaje: "Hubo un error al intentar enviar el mensaje."});
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            <h1>Contacto</h1>
            <div className={styles.container}>

                <div className={styles.box_map}>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d26237.565499147113!2d-58.538045912028124!3d-34.71285470816343!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzTCsDQyJzM5LjUiUyA1OMKwMzEnMjcuNSJX!5e0!3m2!1ses-419!2sus!4v1719357756693!5m2!1ses-419!2sus" width="100%" height="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>

                <div className={styles.box_info}>
                    <span>Obleas Dulces</span>
                    <ul>
                        <li>
                            <div className={styles.icon}>
                                <IconLocation />
                            </div>
                            <div className={styles.txt}>
                                <p> Juan Campion 1857</p>
                                <p>Aldo Bonzi –Buenos Aires (1770)</p>
                            </div>
                        </li>
                        <li>
                            <div className={styles.icon}>
                                <IconPhone />
                            </div>
                            <div className={styles.txt}>
                                <a 
                                href="https://api.whatsapp.com/send?l=es&amp;phone=5491131274647&amp;text=Hola!%20te%20contacto%20desde%20la%20web%20de%obleasdulcesbsas" 
                                target="_blank"
                                >
                                    11-31274647
                                </a>
                            </div>
                        </li>
                        <li>
                            <div className={styles.icon}>
                                <IconSend />
                            </div>
                            <div className={styles.txt}>
                            <a href="mailto:obleasdulces@obleasdulcesbsas.com.ar?subject=Consulta&body=Hola%20Obleas%20Dulces,">obleasdulces@obleasdulcesbsas.com.ar</a>
                            </div>
                        </li>
                        {/*
                        <li>
                            <div className={styles.txt}>
                                <p>Si deseas contactarte por <a>Arrepentimiento de compra presiona click aquí.</a></p>
                            </div>
                        </li>
                        */}
                    </ul>
                </div>

                <div className={styles.box_form} onSubmit={handleSubmit}>
                    <span>Contacto</span>
                    <form>
                        <div>
                            <label>Asunto</label>
                            <input 
                                type="text"
                                name="asunto"
                                placeholder="ej:Servicio al cliente"
                                value={asunto}
                                onChange={handleChange}
                            />
                        </div>
                        <div>
                            <label>Dirección de correo electrónico</label>
                            <input 
                                type="email"
                                name="email"
                                placeholder="Su@email.com"
                                value={correo}
                                onChange={handleChange}
                            />
                        </div>
                        <div>
                            <label>Mensaje</label>
                            <textarea 
                                type="text"
                                name="mensaje"
                                placeholder="¿Cómo podemos ayudarle?"
                                value={mensaje}
                                onChange={handleChange}
                            >

                            </textarea>
                        </div>
                        <div className={styles.container_Btn}>
                            <Btn 
                                cta
                                type="submit"
                                text="Enviar" 
                            />
                        </div>
                    </form>
                    <Alerta 
                        tipoMsj={alerta.tipo}
                        mensaje={alerta.mensaje}
                        estadoAlerta={estadoAlerta}
                        cambiarEstadoAlerta={cambiarEstadoAlerta}
                    />
                </div>

            </div>
            <BannerHomeEnd />
        </>
    );
}
 
export default Contacto;