import React from "react";
import './Btn.scss';
import { Link } from "react-router-dom";
import classNames from "classnames";

const Btn = (props)  => {
    const { className, text, route, type, icon, submit, target } = props;

    const classnames = classNames(
        {
        "link": props.link,
        "link-footer": props.linkFooter,
        "cta": props.cta,
        "back": props.back,
        "menu" : props.menu
        }
    );

    const TypeBtn = () => {
        switch(props.type){
            case "submit":
                return(
                    <button 
                        className={!props.className ?   classnames : 'default'} 
                        type="submit"
                    >
                        {props.text}
                    </button>
                )
                break;
            case "link":
                return(
                    <Link 
                        className={!props.className ?   classnames : 'default'} 
                        to={props.route}
                        target={target}
                    >
                        {props.text}
                    </Link>
                )
                break;
            case "menu":
                return(
                    <Link 
                        className={!props.className ?   classnames : 'default'} 
                        to={props.route}
                    >
                        {props.text}
                    </Link>
                )
                break;
            default:
                break;
        }
    }

    const tag = props.submit ? "button" : "div";
    
    return ( 
        <>
            {TypeBtn()}
        </>
     );
}
 
export default Btn;