import React from "react";
import styles  from "./BoxProduct.module.scss";
import Btn from "../../elements/Btn/Btn";

const BoxProduct = ({item}) => {
    const formattedPrice = Number(item.price).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    return (
        <article className={styles.box}>
            <div className={styles.img}>
                <img src={item.imgMain} />
            </div>
            <div className={styles.name}>
                {item.name + " " + item.units}
            </div>
            <div className={styles.price}>{"$ "+formattedPrice}</div>
            <div className={styles.badge}>{item.iva}</div>
            {/*<!--<div className={styles.containerBtn}>
                <Btn 
                    cta
                    type="link"
                    text="Ver Producto"
                    route={item.route}
                />
            </div>-->*/}
        </article>
    );
}
 
export default BoxProduct;


