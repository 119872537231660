import React, {useState} from "react";
import styles from"./Footer.module.scss";
import Btn from "../../elements/Btn/Btn";
import IconFb from "../../../img/IconFb.js";
import IconIg from "../../../img/IconIg.js";
import Arrow from "../../../img/Arrow.js";
import wpp from "../../../img/i-whatsapp.png";

const Footer = () => {
    const [drop , setDrop] = useState({
        colapsed_1:window.innerWidth <= 768 ? false : true,
        colapsed_2:window.innerWidth <= 768 ? false : true,
        colapsed_3:window.innerWidth <= 768 ? false : true,
        colapsed_4:window.innerWidth <= 768 ? false : true
    });

    const dropDown = (txt) =>{
        if(window.innerWidth <= 768){
            setDrop((prevState) => ({
                ...prevState,
                [txt]: !prevState[txt]
            }));
        }
    }

    return (
        <footer className={styles.footer}>
            <div className={styles.box}>
                <p onClick={() => dropDown("colapsed_1")}>
                    Realizá tu compra<span className={styles.arrow}><Arrow /></span>
                </p>
                {
                    drop.colapsed_1 &&
                    <div>
                        <Btn 
                            cta
                            type="link"
                            text="¡Hacé tu Pedido!"
                            target="blank"
                            route="https://api.whatsapp.com/send?l=es&phone=5491131274647&text=Hola!%20te%20contacto%20desde%20la%20web%20de%obleasdulcesbsas"
                        />
                    </div>
                }
                
            </div>
            <div className={styles.box}>
                <p onClick={() => dropDown(("colapsed_2"))}>Obleas Dulces<span className={styles.arrow}><Arrow /></span></p>
                {
                    drop.colapsed_2 &&
                    <ul>
                        <li>
                            <Btn 
                                type="link"
                                linkFooter
                                text="Nuestros Productos"
                                route="/productos"
                            />
                        </li>
                        <li>
                            <Btn 
                                type="link"
                                linkFooter
                                text="Quienes somos"
                                route="/nosotros"
                            />
                        </li>
                        {/*
                        <li>
                            <Btn 
                                type="link"
                                linkFooter
                                text="Medios de Pago"
                                route="/"
                            />
                        </li>
                        <li>
                            <Btn 
                                type="link"
                                linkFooter
                                text="Arrepentimiento de compra"
                                route="/"
                            />
                        </li>
                        */}
                    </ul>
                }
            </div>
            <div className={styles.box}>
                <p onClick={() => dropDown(("colapsed_3"))}>Contacto<span className={styles.arrow}><Arrow /></span></p>
                {
                    drop.colapsed_3 &&
                    <ul>
                        <li>+ 54 11-31274647</li>
                        <li>L a V de 9 a 18 hs. </li>
                        <li>
                            <a href="mailto:obleasdulces@obleasdulcesbsas.com.ar?subject=Consulta&body=Hola%20Obleas%20Dulces,">obleasdulces@obleasdulcesbsas.com.ar</a>
                        </li>
                        <li>Juan Campion 1857, Aldo Bonzi.</li>
                        <li>Buenos Aires, Argentina. CP 1770.</li>
                    </ul>
                }                
            </div>
            {/*
            <div className={styles.box_redes}>
                <p onClick={() => dropDown(("colapsed_4"))}>Seguinos<span className={styles.arrow}><Arrow /></span></p>
                {
                    drop.colapsed_4 &&
                    <div>
                        <span className={styles.fb}>
                            <IconFb />
                        </span>
                        <span className={styles.ig}>
                            <IconIg />
                        </span>
                    </div>
                }
            </div>
            */}
            <a 
                className={styles.wpp}
                href="https://api.whatsapp.com/send?l=es&amp;phone=5491131274647&amp;text=Hola!%20te%20contacto%20desde%20la%20web%20de%obleasdulcesbsas" 
                target="_blank"
            >
                <img src={wpp} alt="wpp"/>
            </a>
        </footer>
    );
}
 
export default Footer;